@import './variables.scss';

/* Webfont: Lato-Regular */
/* Webfont: Lato-Black */
@font-face {
	font-family: 'LatoWebBlack';
	src: url('fonts/Lato-Black.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-Black.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-Black.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-Black.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BlackItalic */
@font-face {
	font-family: 'LatoWebBlack';
	src: url('fonts/Lato-BlackItalic.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-BlackItalic.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-BlackItalic.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-BlackItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
	font-family: 'LatoWebBold';
	src: url('fonts/Lato-Bold.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-Bold.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-Bold.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */
@font-face {
	font-family: 'LatoWebBold';
	src: url('fonts/Lato-BoldItalic.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-BoldItalic.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-BoldItalic.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-BoldItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Hairline */
@font-face {
	font-family: 'LatoWebHairline';
	src: url('fonts/Lato-Hairline.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-Hairline.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-Hairline.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-Hairline.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-Hairline.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HairlineItalic */
@font-face {
	font-family: 'LatoWebHairline';
	src: url('fonts/Lato-HairlineItalic.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-HairlineItalic.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-HairlineItalic.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-HairlineItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Heavy */
@font-face {
	font-family: 'LatoWebHeavy';
	src: url('fonts/Lato-Heavy.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-Heavy.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-Heavy.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-Heavy.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-Heavy.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HeavyItalic */
@font-face {
	font-family: 'LatoWebHeavy';
	src: url('fonts/Lato-HeavyItalic.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-HeavyItalic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-HeavyItalic.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-HeavyItalic.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-HeavyItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */
@font-face {
	font-family: 'LatoWeb';
	src: url('fonts/Lato-Italic.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-Italic.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-Italic.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-Italic.ttf') format('truetype');
	font-style: italic;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
	font-family: 'LatoWebLight';
	src: url('fonts/Lato-Light.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-Light.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-Light.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-Light.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-LightItalic */
@font-face {
	font-family: 'LatoWebLight';
	src: url('fonts/Lato-LightItalic.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-LightItalic.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-LightItalic.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-LightItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
	font-family: 'LatoWebMedium';
	src: url('fonts/Lato-Medium.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-Medium.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-Medium.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-Medium.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-MediumItalic */
@font-face {
	font-family: 'LatoWebMedium';
	src: url('fonts/Lato-MediumItalic.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-MediumItalic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-MediumItalic.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-MediumItalic.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-MediumItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
	font-family: 'LatoWeb';
	src: url('fonts/Lato-Regular.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-Regular.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-Regular.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-Regular.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */
@font-face {
	font-family: 'LatoWebSemibold';
	src: url('fonts/Lato-Semibold.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-Semibold.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-Semibold.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-Semibold.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-SemiboldItalic */
@font-face {
	font-family: 'LatoWebSemibold';
	src: url('fonts/Lato-SemiboldItalic.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-SemiboldItalic.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-SemiboldItalic.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-SemiboldItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */
@font-face {
	font-family: 'LatoWebThin';
	src: url('fonts/Lato-Thin.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-Thin.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-Thin.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-Thin.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-Thin.ttf') format('truetype');
	font-style: normal;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}

/* Webfont: Lato-ThinItalic */
@font-face {
	font-family: 'LatoWebThin';
	src: url('fonts/Lato-ThinItalic.eot');
	/* IE9 Compat Modes */
	src: url('fonts/Lato-ThinItalic.eot?#iefix') format('embedded-opentype'),
		/* IE6-IE8 */
		url('fonts/Lato-ThinItalic.woff2') format('woff2'),
		/* Modern Browsers */
		url('fonts/Lato-ThinItalic.woff') format('woff'),
		/* Modern Browsers */
		url('fonts/Lato-ThinItalic.ttf') format('truetype');
	font-style: italic;
	font-weight: normal;
	text-rendering: optimizeLegibility;
}


.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: $background-corporate;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: $link-color;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.ListTable-striped tbody>tr:nth-child(even) {
	background-color: $stripedTableColor;
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
}


.icon {
	font-size: 1.5rem;
	color: #dc3545;
	width: 1em;
	height: 1em;
	display: inline-block;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	flex-shrink: 0;
	user-select: none;

}

.iconWhite {
	font-size: 1.5rem;
	color: white;
	width: 1em;
	height: 1em;
	display: inline-block;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	flex-shrink: 0;
	user-select: none;

}

[class*=MuiTypography-body] {
	font-size: 0.875rem !important;
}
.RaImageField-image {
	display: flex;

	max-width: calc(100vw * 0.5);
	max-height: calc(100vh * 0.5);
//	max-height: 100px;
	object-fit: scale-down;
}
.time_event{
	min-width: 200px !important;
}
.date-admin{
	min-width: 200px !important;
}
.custom-label-form{
	margin-right: 20px;

}