.map {
	min-height: 400px;
	min-width: 1050px !important;
//	width: calc(100vw - 300px);
	display: flex;
	margin-top: 20px;
}

.map .infowindow-content {
	display: inline;
}

.map > div {
	width: 100% !important;
	min-height: 300px !important;
}

.map-maker-icon {
	color: #fd6c6c;
}

.github-icon {
	font-size: 24px;
}

.github-link {
	color: #262626;
	font-size: 20px;
}

.github-link:hover,
.github-link:active {
	color: #4078c0;
	text-decoration: none;
}

.suggestion-icon {
	margin-right: 8px;
}

.search-bar-container {
	/*width: 90%;
    max-width: 500px;
    */
	margin: 10px auto 0;
}

.search-input-container {
	position: relative;
}
.location-search-input {
	width: 100%;
}

.search-input,
.search-input:focus,
.search-input:active {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
	border: honeydew;
	display: block;
	width: 100%;
	padding: 16px;
	font-size: 16px;
	border-radius: 2px;
	outline: none;
}

.clear-button,
.clear-button:active,
.clear-button:focus {
	position: relative;
	right: -10px;
	top: 20px;
	transform: translateY(-50%);
	background: transparent;
	border: none;
	outline: none;
	font-weight: 600;
	color: #999;
	font-size: 20px;
}

.autocomplete-container {
	border-bottom: honeydew;
	border-left: honeydew;
	border-right: honeydew;
	border-top: 1px solid #e6e6e6;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	border-radius: 0 0 2px 2px;
}

.suggestion-item {
	padding: 8px;
	text-align: left;
	background-color: #fff;
	cursor: pointer;
}

.suggestion-item--active {
	background-color: #fafafa;
}

.dropdown-footer {
	display: flex;
	justify-content: flex-end;
	padding: 4px;
}

.dropdown-footer-image {
	display: inline-block;
	width: 150px;
}

.spinner {
	color: #18bc9c;
	font-size: 30px;
}

.error-message {
	color: red;
}

.geocode-result-header {
	font-size: 20px;
	color: #222222;
}

@media (max-width: 480px) {
	.autocomplete-container {
		text-align: left;
	}
}

@media (min-width: 768px) {
	.alert {
		margin-left: auto;
		margin-right: auto;
		width: 50%;
		-webkit-animation: fadein .75s;
		-moz-animation: fadein .75s;
		-ms-animation: fadein .75s;
		-o-animation: fadein .75s;
		animation: fadein .75s;
	}

	.btn {
		margin-top: 1.5rem;
	}

	.form-group {
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
		margin-top: 5rem;
		width: 50%;
	}
}
.divInput {
	margin-top: 16px;
	margin-bottom: 8px;
}

.field {
	width: 100%;
}
.oculto{
	display: none;
}
.location-search-input{
	width: 100%;
	min-width:600px !important;
}